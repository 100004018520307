export class EnumSymbol {
    sym;
    value;
    text;
    parent;

    constructor(name, { value, text }) {
        this.sym = Symbol.for(name);

        if (!Object.is(value, undefined)) {
            this.value = value;
        }

        if (text) {
            this.text = text;
        }

        Object.freeze(this);
    }

    get text() {
        return this.text || Symbol.keyFor(this.sym);
    }

    get value() {
        return this.value;
    }

    toString() {
        return this.text || Symbol.keyFor(this.sym);
    }
}

export class Enum {
    constructor(enumLiterals) {
        for (let key in enumLiterals) {
            if (!enumLiterals[key]) throw new TypeError('each enum should have been initialized with atleast empty {} value');
            this[key] = new EnumSymbol(key, enumLiterals[key]);
        }
        Object.freeze(this);
    }

    values() {
        return Object.keys(this).map((key) => this[key]);
    }

    keys() {
        return Object.keys(this);
    }

    byValue(value) {
        return this.values().find((e) => e.value === value);
    }

    contains(symbol) {
        return symbol instanceof EnumSymbol && this[Symbol.keyFor(symbol.sym)].sym === symbol.sym;
    }
}
