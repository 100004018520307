<template>
  <div id="principal" class="container-fluid m-0 p-0">
    <div v-if="telaPronta" class="d-flex flex-column bg-white">
      <div id="idDetalhesFiltros">
        <div class="d-flex flex-column p-2 fz-80 text-muted w-100 shadow-sm mb-2">
          <p class="align-items-start text-left m-0">
            <span class="label-orange">Cód. Descrição: </span>
            <span class="text-muted size-font-label"> {{ balancoSelecionado.IdBalanco }} - {{ balancoSelecionado.Descricao }} </span>
          </p>
          <p class="align-items-start text-left m-0">
            <span class="label-orange">Data: </span>
            <span class="text-muted size-font-label">
              {{ balancoSelecionado.DataLancamentoFormat }}
            </span>
          </p>
          <p class="align-items-start text-left m-0">
            <span class="label-orange">Quantidade Seções: </span>
            <span class="text-muted size-font-label"> {{ quantidadeSecoes }} </span>
          </p>
        </div>
      </div>

      <div class="text-center card-info-search m2">
        <div v-if="todasSecoesConcluidas" class="d-flex flex-column align-items-center justify-content-center my-2">
          <div>
            <span class="text-center">
              <img :src="imgIconWarning" />
            </span>
          </div>
          <div>
            <span class="text-uppercase text-muted fz-70"> Todas as seções deste balanço já foram concluídas. </span>
          </div>
        </div>
      </div>
      <div id="idGridSecoes" class="d-flex flex-column mx-1 px-1 p-0" v-if="listaSecoes.length">
        <div class="shadow-sm shadow-hover my-1" v-for="(secao, index) in listaSecoes" v-bind:key="index">
          <div class="card-header bg-primary text-white size-font-label m-0 py-0 pl-2">{{ secao.Id }} - {{ secao.Descricao }}</div>
          <div
            class="d-flex border"
            :class="{ 'border border-primary bg-light': secaoSelecionada && secao.Id == secaoSelecionada.Id && secao.Etapa == secaoSelecionada.Etapa }"
            @click="selecionaSecao(secao)"
          >
            <div class="mr-auto p-2 w-100">
              <div class="d-flex justify-content-between w-100">
                <div class="text-right d-flex flex-column fz-90 mr-1">
                  <p class="align-items-start text-left m-0">
                    <small class="label-orange">Nome: </small>
                    <span class="text-muted size-font-label">
                      {{ secao.Descricao }}
                    </span>
                  </p>
                  <p class="align-items-start text-left m-0" v-if="secao.LocalizacaoInicial && secao.LocalizacaoFinal">
                    <small class="label-orange">Range: </small>
                    <span class="text-muted size-font-label">
                      {{ obterRangeLocalizacao(secao) }}
                    </span>
                  </p>
                  <p class="align-items-start text-left m-0" v-if="secao.Etapa">
                    <small class="label-orange">Etapa: </small>
                    <span class="text-muted size-font-label"> {{ secao.Etapa }}ª conferência </span>
                  </p>
                </div>
                <div class="d-flex flex-column justify-content-end text-right">
                  <span class="badge badge-pill" :class="{ 'badge-default': secao.IdStatusSecao == 4, 'badge-info': secao.IdStatusSecao == 5, 'badge-success': secao.IdStatusSecao == 6 }">
                    {{ obterDescricaoStatusSecao(secao.IdStatusSecao) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="fixed-bottom bg-white mb-0">
        <div class="btn-group w-100 flex justify-content-end">
          <button :class="['btn btn-default ', isMobile ? 'btn-block mx-0 my-1' : 'm-1']" @click="$router.go(-1)">
            <i class="fas fa-arrow-left fa-lg pl-2"></i>
            Voltar
          </button>
          <button :disabled="!habilitarExecucaoSecao" :class="['btn btn-secondary', isMobile ? 'btn-block mx-0 my-1' : 'm-1']" @click="executarSecao()">
            Executar
            <i class="fas fa-play fa-lg pl-2"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import statusSecaoBalanco from '@/domain/status-secao-balanco';
import loading from '@/common/loading';

const imgIconWarning = require('@/assets/Images/linx-icon-ilustrativo/check-circle.png');

export default {
  name: 'secao-balanco',
  props: ['idBalanco'],
  components: {},
  data() {
    return {
      telaPronta: false,
      statusSecaoBalanco,
      listaSecoes: [],
      imgIconWarning: imgIconWarning,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'appData/ehWMSMobile',
      portaisUsuario: 'auth/portaisUsuario',
      empresasUsuario: 'auth/empresasUsuario',
      balancoSelecionado: 'balanco/balancoSelecionado',
      secaoSelecionada: 'balanco/secaoSelecionada',
    }),
    habilitarExecucaoSecao() {
      return this.secaoSelecionada && this.secaoSelecionada.IdStatusSecao != this.statusSecaoBalanco.CONCLUIDO.value;
    },
    todasSecoesConcluidas() {
      return this.balancoSelecionado.Secao.every((secao) => secao.IdStatusSecao == this.statusSecaoBalanco.CONCLUIDO.value);
    },

    quantidadeSecoes() {
      //Seções são agrupadas por Id, pois podem possuir até 3 etapas
      return this.$_.chain(this.listaSecoes)
        .groupBy((x) => x.Id)
        .map((Id) => ({ Id }))
        .value().length;
    },
  },
  mounted() {
    this.telaPronta = true;

    this.obterSecoesBalanco();
  },

  methods: {
    obterSecoesBalanco() {
      var self = this;
      let params = {
        IdBalanco: this.idBalanco,
      };

      loading.exibir();
      this.$store
        .dispatch('balanco/listarSecoesBalancoParaExecucao', params)
        .then((resp) => {
          self.listaSecoes = resp.Secoes.filter(function (secao) {
            return (
              secao.IdStatusSecao != self.statusSecaoBalanco.CONCLUIDO.value &&
              (!secao.Etapa ||
                secao.Etapa < 3 ||
                (self.balancoSelecionado.Secao.find((f) => {
                  return f.Etapa == 1;
                }).IdStatusSecao == self.statusSecaoBalanco.CONCLUIDO.value &&
                  self.balancoSelecionado.Secao.find((f) => {
                    return f.Etapa == 2;
                  }).IdStatusSecao == self.statusSecaoBalanco.CONCLUIDO.value))
            );
          });
        })
        .catch((err) => {
          this.$helper.globalErrorHandler(err, 'Não foi possível obter os Balanços, tente novamente');
          this.$router.go(-1);
        })
        .finally(() => {
          this.telaPronta = true;
          loading.ocultar();
        });
    },
    selecionaSecao(secao) {
      this.$store.dispatch('balanco/atualizaSecaoSelecionada', secao);
    },
    executarSecao() {
      this.$router.push({ name: 'execucao-secao' });
    },
    obterRangeLocalizacao(secao) {
      return `${secao.LocalizacaoInicial} à ${secao.LocalizacaoFinal}`;
    },
    obterDescricaoStatusSecao(idStatus) {
      switch (idStatus) {
        case 4:
          return this.statusSecaoBalanco.PENDENTE.text;
        case 5:
          return this.statusSecaoBalanco.EM_CONFERENCIA.text;
        case 6:
          return this.statusSecaoBalanco.CONCLUIDO.text;
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>
.border-primary {
  border-width: 2px !important;
}
</style>
